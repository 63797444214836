@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}
html,
body {
  overflow-x: hidden;
}
.custom-slider button.slick-next {
  right: 80px;
  z-index: 10;
}

.custom-slider button.slick-prev {
  left: 20px;
  z-index: 10;
}

.custom-slider button.slick-next::before {
  color: gray;
  content: "\276F";
  background-color: #ffd3d3;
  width: 80px;
  height: 80px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-slider button.slick-prev::before {
  color: gray;
  content: "\276E";
  background-color: #ffd3d3;
  width: 80px;
  height: 80px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider button.slick-next {
  right: 0px;
  z-index: 10;
  top: 55px;
}

.image-slider button.slick-prev {
  left: 0px;
  top: 55px;
  z-index: 10;
}

.image-slider button.slick-next::before {
  color: gray;
  content: "\276F";
  background-color: #1c20ff;
  width: 40px;
  height: 40px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-slider button.slick-prev::before {
  color: gray;
  content: "\276E";
  background-color: #1c20ff;
  width: 40px;
  height: 40px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  appearance: none;
}

/* .my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.my-masonry-grid_column {
    background-clip: padding-box;
} */

.my-masonry-grid_column > div {
  margin-bottom: 20px;
}

.w500 {
  width: 500px;
}

code {
  white-space: initial;
}

.my-auto {
  margin: auto 0;
}
